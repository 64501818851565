import React, { useEffect, useState, useMemo } from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";

import useBranchLink from "hooks/useBranchLink";
import useReferralData from "hooks/useReferralData/useReferralData";
import QRCode from "react-qr-code";
import styles from "./BranchQRCode.module.scss";

export interface Props {
  className?: string;
  size: number;
}

function BranchQRCode({ className, size }: Props): JSX.Element {
  const intl = useIntl();

  const defaultUrl = useMemo(
    () =>
      new URL(
        `https://peanut.app.link/default${
          intl.locale === "en" ? "" : `-${intl.locale}`
        }`
      ).toString(),
    [intl.locale]
  );

  const { url: branchUrl, fetchUrl } = useBranchLink(defaultUrl);
  const { isReferredUser } = useReferralData();
  const [processing, setProcessing] = useState(isReferredUser);

  useEffect(() => {
    if (!isReferredUser) return;

    fetchUrl();
    setProcessing(true);
  }, [isReferredUser]);

  useEffect(() => {
    if (branchUrl) {
      setProcessing(false);
    }
  }, [branchUrl]);

  const distinationUrl = branchUrl || defaultUrl;

  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.blur]: processing,
      })}
      data-testid="branch-qrcode-wrapper"
      data-fallback-url={!processing ? distinationUrl : undefined}
    >
      {/* 
// @ts-ignore */}
      <QRCode value={distinationUrl} size={size} bgColor="transparent" />
    </div>
  );
}

export default BranchQRCode;
