import React, { useMemo } from "react";
import classNames from "classnames";
import FormattedMessage from "helpers/formattedMessage";
import imageStar from "./assets/rating-star.svg";
import imageStarHalf from "./assets/rating-star-half.svg";
import styles from "./Rating.module.scss";

export interface Props {
  className?: string;
  showRatedText?: boolean;
  value: number;
}

function Rating({
  className,
  showRatedText = true,
  value,
}: Props): JSX.Element {
  const ratingImage = useMemo(
    () => (
      <>
        <span className={styles.stars}>
          {[...Array(Math.floor(value))].map((_x, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <img key={`star_${index}`} src={imageStar} alt="Star" />
          ))}
        </span>
        {value % 1 !== 0 && (
          <span className={styles.stars}>
            <img id="star-half" src={imageStarHalf} alt="Star-Half" />
          </span>
        )}
      </>
    ),
    [value]
  );

  return (
    <div className={classNames(styles.wrapper, className)} data-testid="rating">
      <p className={styles.rating}>
        {showRatedText && (
          <span className={styles.ratingText}>
            <FormattedMessage
              defaultMessage="Rated 4.4"
              description="Rating for the QR code card"
              id="components.qrcodecard.rating"
            />
          </span>
        )}
        <span className={styles.rating}>{ratingImage}</span>
      </p>{" "}
      <p className={styles.subtitle}>
        <span>
          <FormattedMessage
            defaultMessage="Trusted by 5M+ women"
            description="Subtitle for the QR code card"
            id="components.qrcodecard.subtitle"
          />
        </span>
      </p>
    </div>
  );
}

export default Rating;
