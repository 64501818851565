import React from "react";
import PropTypes from "prop-types";
import styles from "./QRCodeCard.module.scss";
import FormattedMessage from "helpers/formattedMessage";
import { useIntl } from "react-intl";

import classNames from "classnames";
import Rating from "components/Rating";
import BranchQRCode from "components/BranchQRCode/BranchQRCode";

export interface Props {
  qrSize?: number;
  className?: string;
  customTitle?: React.ReactNode; // Add the customTitle prop
}

const QRCodeCard = ({ qrSize = 130, className, customTitle }: Props): JSX.Element => {
  const intl = useIntl();

  // Function to render the default or custom title based on the customTitle prop
  const renderTitle = () => {
    if (customTitle) {
      return customTitle; // If customTitle is provided, use it
    }
    // Fallback to the default title if customTitle is not provided
    return (
      <FormattedMessage
        defaultMessage="Scan to Join"
        description="Title for the QR code card"
        values={{ linebreak: <br /> }}
        id="components.newqrcodecard.title"
      />
    );
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        intl.locale === "es" ? styles.es : "",
        className
      )}
    >
      <div className={styles.leftPane}>
        <BranchQRCode size={qrSize} className={styles.qrCode} />
      </div>
      <div className={styles.rightPane}>
        <div className={styles.title}>
          {renderTitle()}
        </div>
        <Rating value={4.3} />
      </div>
    </div>
  );
};

export default QRCodeCard;
